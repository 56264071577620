import { createMemo } from "solid-js";
import { useWire } from "~/wire";
import type { IdentityService } from "../service";

export const selectIsIdentityConnecting = (identity: IdentityService) =>
  !identity.isIdentityReady(identity.snapshot.value, identity.snapshot.context.identity.workingContext.projectId);

export const useIsIdentityConnecting = () => {
  const wire = useWire();
  return createMemo(() => selectIsIdentityConnecting(wire.services.identity));
};
