import { stAnalytics } from "@repo/analytics";
import { type CommandKey, codes, getRequestClient, type stid, type threads } from "@repo/client";
import { type Logger, Named } from "@repo/logger";
import type { Editor } from "@tiptap/core";
import { useActor } from "@xstate/solid";
import { createMemo, createResource } from "solid-js";
import { getThreadEventProperties } from "~/domains/analytics/useThreadEventProperties";
import { selectIsIdentityConnecting } from "~/domains/identity/hooks";
import type { IdentityService } from "~/domains/identity/service";
import type { KnowledgeService } from "~/domains/knowledge/service";
import type { LimitingService } from "~/domains/limiting";
import { useThreadMachine } from "~/domains/threads/machines";
import { threadEventFactory } from "~/domains/threads/machines/threadsEventProducers";
import type { Prompt } from "~/domains/threads/types";
import { newSendToServerEvent } from "~/domains/ws/machines";
import type { useWebsocketService } from "~/domains/ws/service/wsService";
import { getMessagesWithInserts } from "../machines/threadInserts";

export type ThreadServiceDependencies = {
  logger: Logger;
  websocketService: ReturnType<typeof useWebsocketService>;
  identityService: IdentityService;
  limitingService: LimitingService;
  knowledgeService: KnowledgeService;
};

export type ThreadService = ReturnType<typeof useThreadService>;

export const useThreadService = (deps: ThreadServiceDependencies) => {
  const childLogger = new Named(deps.logger, "threadService");
  const eventFactory = threadEventFactory(childLogger);

  const threadsListResource = useThreadsList(deps);

  const createThread = async (params: { label: string; projectId: string }) => {
    childLogger.info("creating thread", params);

    const [_threadsList, { refetch }] = threadsListResource;

    const client = getRequestClient(deps.identityService.getIdentityToken);
    const results = await client.controlplane.CreateThread({
      label: params.label,
      projectId: params.projectId,
    });

    if (results.code !== codes.OK) {
      throw Error(results.message);
    }

    stAnalytics.track(
      "thread_created",
      getThreadEventProperties({
        workingContext: deps.identityService.snapshot.context.identity.workingContext,
        threadId: results.data.threadId,
        threadMessages: messages(),
      }),
    );

    deps.limitingService.guest.addAllowedThread(results.data.threadId);
    refetch();

    return results;
  };

  const loadThread = async (threadId: stid.ThreadStringID) => {
    childLogger.info("loading thread", {
      threadId,
      token: deps.identityService.getIdentityToken(),
    });
    const client = getRequestClient(deps.identityService.getIdentityToken);
    const results = await client.controlplane.GetThreadByID(threadId);
    childLogger.info("API call to get thread complete", { results });

    if (results.code !== codes.OK) {
      throw Error(results.message);
    }

    stAnalytics.track(
      "thread_opened",
      getThreadEventProperties({
        workingContext: deps.identityService.snapshot.context.identity.workingContext,
        threadId: results.data.threadId,
        threadMessages: messages(),
      }),
    );
    return results;
  };

  /**
   * onSendServerWSMessage is a callback that is called when the state machine wants to send a message to the server.
   * @param commandKey
   * @param recipients
   * @param data
   */
  const onSendServerWSMessage = (commandKey: CommandKey, recipients: string[], data: string): void => {
    deps.websocketService.send(newSendToServerEvent(commandKey, recipients, data));
  };

  const [snapshot, send, actor] = useActor(
    useThreadMachine({
      logger: deps.logger,
      createThread,
      loadThread,
      onSendServerWSMessage,
      knowledgeService: deps.knowledgeService,
      identityService: deps.identityService,
      websocketService: deps.websocketService,
    }),
    // { inspect: isDev ? createBrowserInspector().inspect : undefined },
  );

  const messages = createMemo(() => getMessagesWithInserts({ ...deps, messages: snapshot.context.messages }));

  const updateEditorRef = (editorRef: Editor | null) => {
    send(eventFactory.newUpdateEditorRef(editorRef));
  };

  const logger = new Named(deps.logger, "threadService");
  actor.start();

  if (_LOG) logger.info("service started");

  const getSignedURLForMessageDiagnostics = async (messageID: stid.MessageStringID): Promise<string> => {
    const client = getRequestClient(deps.identityService.getIdentityToken);
    const results = await client.controlplane.GenerateSignedURLForMessageDiagnostics(messageID);
    if (results.code === codes.OK) {
      await navigator.clipboard.writeText(results.data);
      alert("Copied to Clipboard");
    }
    return "";
  };

  const sendThreadKnowledge = async (params: {
    knowledge: threads.Knowledge;
  }) => send(threadEventFactory(logger).newSendKnowledgeChangeEvent(params.knowledge));

  const sendThreadPrompt = async (params: {
    prompt: Prompt;
  }) => send(threadEventFactory(logger).newSendPrompt(params.prompt));

  const deleteThread = async (threadId: stid.ThreadStringID) => {
    const client = getRequestClient(deps.identityService.getIdentityToken);
    const results = await client.controlplane.DeleteThread(threadId);
    const [_, { removeThreadFromList }] = threadsListResource;

    removeThreadFromList(threadId);

    if (results.code !== codes.OK) {
      // send(eventFactory.newFailedEvent(results.message));
      return;
    }
    // send(eventFactory.newThreadDeletedEvent(threadId));
    return;
  };

  return {
    messages,
    snapshot,
    send,
    actor,
    eventFactory,
    deleteThread,
    getSignedURLForMessageDiagnostics,
    sendThreadKnowledge,
    sendThreadPrompt,
    updateEditorRef,
    threadsListResource,
  };
};

const useThreadsList = ({ identityService, limitingService }: ThreadServiceDependencies) => {
  const client = getRequestClient(identityService.getIdentityToken);
  const isConnecting = () => selectIsIdentityConnecting(identityService);
  const [threads, { mutate, refetch }] = createResource(
    () => !isConnecting(),
    async () => {
      const res = await client.controlplane.GetThreadsRecentForUser({
        Page: 0,
      });

      // Filter out disallowed threads
      res.data = res.data?.filter((t) => limitingService.guest.isThreadAllowed(t.threadId));

      return res;
    },
  );
  const updateThreadLabel = (threadId: string, label: string) => {
    mutate((t) => {
      if (!t?.data) return t;
      const index = t.data.findIndex((item) => item.threadId === threadId);
      if (index === -1) return t;
      const newData = t.data.map((item) => {
        if (item.threadId === threadId) return { ...item, label };
        return item;
      });

      return { ...t, data: newData };
    });
  };

  const removeThreadFromList = (threadId: string) => {
    mutate((t) => {
      if (!t?.data) return t;

      return {
        ...t,
        data: t.data?.filter((thread) => thread.threadId !== threadId),
      };
    });
  };
  return [threads, { updateThreadLabel, refetch, removeThreadFromList }] as const;
};
