import { isMessagePrompt, isMessageTextV1, type MessageInsert, type ThreadMessage } from "@repo/client";
import { getThreadCampaignPrompt, getThreadTransformationId } from "./computeThreadData";
import type { ThreadServiceDependencies } from "..";

type InsertCheckFn = (deps: InsertsDeps) => {
  allowNext: boolean;
};

const SKIP = { allowNext: true };
type InsertsDeps = ThreadServiceDependencies & { messages: ThreadMessage[] };

// Creates a new array of messages for a thread, including inserts
export const getMessagesWithInserts = (deps: InsertsDeps) => {
  const newDeps = {
    ...deps,
    // Making a new array since it will get mutated by the insert functions
    messages: [...deps.messages],
  };
  // Loop through through the insert functions in order, each function can mutate newMessages as it pleases
  // If an insert function should be skipped, it can early return `SKIP` without mutating newMessages
  for (const check of ALL_INSERT_CHECKS) {
    if (!check(newDeps).allowNext) break;
  }

  return newDeps.messages;
};

const fileUploadCheck: InsertCheckFn = ({ messages }) => {
  if (messages.length !== 2) return SKIP;

  const [prompt, response] = messages;
  if (!isMessagePrompt(prompt) || !isMessageTextV1(response)) return SKIP;
  if (!response.provenance.processedByModel.includes("storytell")) return SKIP;
  if (!response.isDone) return SKIP;

  const transformationId = getThreadTransformationId(messages);
  if (!transformationId) return SKIP;

  const campaignPrompt = getThreadCampaignPrompt(transformationId);
  if (!campaignPrompt) return SKIP;
  if (!campaignPrompt.requiresUpload) return SKIP;

  // All checks have cleared, put a file upload insert at the bottom of the thread
  messages.push({
    kind: "thread-insert",
    insertType: "file-upload",
    messageId: "insert",
    label: "File upload",
  });
  return { allowNext: false };
};

const signUpLimit: InsertCheckFn = ({ messages, limitingService }: InsertsDeps) => {
  if (limitingService.guest.isInteractionAllowed()) return SKIP;

  messages.push({
    kind: "thread-insert",
    insertType: "sign-up-limit",
    messageId: "insert",
    label: "Sign up",
  });
  return { allowNext: false };
};

// Be mindful when adding new inserts, their order matters
const ALL_INSERT_CHECKS = [signUpLimit, fileUploadCheck];
