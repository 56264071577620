import type { ThreadEventProps } from "@repo/analytics";
import { isMessagePrompt, isMessageTextV1, type ThreadMessage } from "@repo/client";
import { useWire } from "~/wire";
import type { WorkingContext } from "../identity/types";

export const useThreadEventProperties = () => {
  const wire = useWire();

  const props = () =>
    getThreadEventProperties({
      workingContext: wire.services.identity.snapshot.context.identity.workingContext,
      threadId: wire.services.threads.snapshot.context.threadId,
      threadMessages: wire.services.threads.messages(),
    });

  return { threadEventProps: props };
};

export const getThreadEventProperties = (deps: {
  workingContext: WorkingContext;
  threadId: string | null;
  threadMessages: ThreadMessage[];
}): ThreadEventProps => ({
  ...deps.workingContext,
  threadId: deps.threadId,
  threadPromptCount: deps.threadMessages.filter((m) => isMessagePrompt(m)).length,
  threadResponseCount: deps.threadMessages.filter((m) => isMessageTextV1(m)).length,
});
