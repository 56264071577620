import { isMessagePrompt, type threads } from "@repo/client";
import type { Editor } from "@tiptap/core";
import { createEffect, createMemo, createSignal } from "solid-js";
import type { ThreadService } from "~/domains/threads/service";

/**
 * Static store to keep track of prompt input history.
 *
 * @see {@link https://linear.app/storytell/issue/ENG-1445/give-me-a-prompt-history|ENG-1445}
 */
export const useInputHistoryService = (dep: Pick<ThreadService, "messages">) => {
  let lastCursorPosition = 1;

  // We define a list of commands that disable our cursor key controls, avoiding conflicting behaviour.
  const commands = ["/", "#"];

  // We add an empty string to the end of our history array to ensure the last entry is always empty.
  const history = createMemo(() =>
    dep
      .messages()
      .filter((message): message is threads.MessagePromptV1 => isMessagePrompt(message))
      .map((message) => message.prompt),
  );
  const [getPosition, setPosition] = createSignal(1);
  const [isFirst, setIsFirst] = createSignal(true);
  const [isLast, setIsLast] = createSignal(true);

  createEffect(() => {
    setIsFirst(getPosition() === 0 || history().length === 1);
    setIsLast(getPosition() === history().length - 1 || history().length === 1);
  });

  createEffect(() => {
    if (history()[history().length - 1] !== "") {
      history().push("");
      setPosition(history().length - 1);
      lastCursorPosition = 1;
    }
  });

  const lastEntry = () => history()[history().length - 1];

  /**
   * Updates the latest value in history (current).
   */
  const setCurrent = (value: string) => {
    history()[history().length - 1] = value;
  };

  /**
   * Creates a new entry in our history array.
   */
  const createHistoryEntry = (value = "") => {
    history().push(value);
  };

  /**
   * Returns the previous value in history.
   */
  const getPrevious = (editor: Editor | null) => {
    if (!editor) return "";

    //  Given user has pressed the UpArrow key.
    //  When they are at the start of the history array.
    //  Then we take no action.
    if (isFirst()) return "";

    //  Given user has pressed the UpArrow key.
    //  When: They are navigating the history array.
    //  - And are not at the first entry or last entry.
    //  Then we decrement the position.
    //  - And set the editor content to the previous history entry.
    const position = getPosition();
    if (position > 0) {
      setPosition(position - 1);
      editor.commands.setContent(history()[getPosition()] ?? "");
      history()[getPosition()] = editor.getText();
    }
  };

  /**
   * Returns the next value in history.
   */
  const getNext = (editor: Editor | null) => {
    if (!editor) return "";
    if (isLast()) return "";

    //  Given the user has pressed the DownArrow key.
    //  - And is not at the last entry in the history array.
    //  When the prompt bar is empty.
    //  Then we set the editor content to appropriate value.
    setPosition(getPosition() + 1);
    editor.commands.setContent(history()[getPosition()] ?? "");
    history()[getPosition()] = editor.getText();
  };

  /**
   * Keyboard event listener for up and down arrow keys.
   */
  const keyboardHandler = (event: KeyboardEvent, editor: Editor | null, focused: boolean) => {
    if (!editor) return;

    const text = editor?.getText();

    //	We ignore our handlers when the last character input could be considered a command.
    if (commands.includes(text.charAt(text.length - 1))) return;

    //	Make sure there is no text selection by comparing the from and to range.
    //	Event should also not triggered when a user first moves the cursor to start/end.
    //	We pause and wait for a second key press.
    if (editor.state.selection.from === editor.state.selection.to) {
      //  Given the user is typing in the prompt.
      //  When they have navigated the prompt history.
      //  - And has typed a new character into a history entry.
      //  Then we set the current position to last.
      //  - And create a new history entry as required.
      if (!isLast() && text.trim() !== history()[getPosition()]?.trim()) {
        if (history()[history().length - 1] !== "") {
          createHistoryEntry();
        }
        setPosition(history().length - 1);
        setCurrent(text);
      }
      //  Given the user is typing in the prompt.
      //  When they at the last entry in the history array.
      //  - And have typed a new character.
      //  Then we update the current history entry.
      else if (isLast() && text.trim() !== history()[getPosition()]?.trim()) {
        setCurrent(text);
      }

      if (lastCursorPosition === editor.state.selection.from) {
        if (event.key === "ArrowUp" && focused && editor.state.selection.from === 1) {
          getPrevious(editor);
          event.stopImmediatePropagation();
        } else if (event.key === "ArrowDown" && editor.state.selection.from === editor.state.doc.content.size - 1) {
          getNext(editor);
          event.stopImmediatePropagation();
        }
      }
    }

    lastCursorPosition = editor.state.selection.from;
  };

  return {
    setCurrent,
    getNext,
    getPrevious,
    isFirst,
    isLast,
    createHistoryEntry,
    keyboardHandler,
  };
};
