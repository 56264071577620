/**
 * Browser local/session storage keys.
 */
export const keys = {
  /**
   * Stores the originating URL before a user enters the signup journey.
   */
  SIGNUP_REFERRER_URL: "signup:referrer",

  /**
   * Stores whether a user has already experienced the prompt URL param tutorial on homepage.
   */
  USER_EXPERIENCED_PROMPT_TUTORIAL: "user:prompt_tutorial:complete",

  /**
   * Stores the last used magic link email.
   */
  LAST_USED_MAGIC_LINK_EMAIL: "magicLinkLastEnteredEmail",

  /**
   * Time of last prompt submission via URL.
   */
  DATE_LAST_USED_PROMPT_PARAM: "marketing:homepage:last_prompt_param",

  /**
   * Feedback email address.
   */
  FEEDBACK_EMAIL: "feedback-email",
};
