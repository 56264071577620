import { Named, type Logger } from "@repo/logger";
import { createEffect, createResource, on } from "solid-js";
import type { useDeviceService } from "~/domains/device/service/deviceService";
import { fetchExperimentsServer, mapExperimentsToPosthogFeatureFlags } from "@repo/experiments";
import { useSearchParams } from "@solidjs/router";

export type FeatureFlagServiceDependencies = {
  logger: Logger;
  deviceService: ReturnType<typeof useDeviceService>;
};

export const useExperimentsService = (deps: FeatureFlagServiceDependencies) => {
  const logger = new Named(deps.logger, "featureFlagsService");
  const [params] = useSearchParams();
  const [experiments] = createResource(
    deps.deviceService.deviceId,
    async (id) => await fetchExperimentsServer(id, params, logger),
  );
  createEffect(() => {
    // We need to set the flags on the window so that the posthog client-side initializer
    // Picks up the values when bootstrapping

    const exp = experiments();
    if (!exp) return;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    (window as any).__ST_EXPERIMENTS_BOOTSTRAP__ = mapExperimentsToPosthogFeatureFlags(exp);
  });
  return {
    data: experiments,
  };
};

export type ExperimentsService = ReturnType<typeof useExperimentsService>;
