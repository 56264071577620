import { getRequestClient, type operations, type q } from "@repo/client";
import { batch, createResource } from "solid-js";
import { createStore } from "solid-js/store";
import { selectIsIdentityConnecting } from "~/domains/identity/hooks";
import type { IdentityService } from "~/domains/identity/service";

export type KnowledgeServiceDeps = {
  identityService: IdentityService;
};

export type KnowledgeService = ReturnType<typeof useKnowledgeService>;

export const useKnowledgeService = (deps: KnowledgeServiceDeps) => {
  const client = getRequestClient(deps.identityService.getIdentityToken);
  const isConnecting = () => selectIsIdentityConnecting(deps.identityService);

  const [assetsCache, setAssetsCache] = createStore({
    byId: {} as Record<string, q.ControlplaneSsAsset>,
  });

  const [data, setData] = createStore({
    queuedKnowledgeChange: {
      added: [] as string[],
      removed: [] as string[],
    },
  });

  const resetQueuedKnowledgeChange = () =>
    setData("queuedKnowledgeChange", {
      added: [],
      removed: [],
    });
  const addQueuedKnowledgeChange = (asset: string) =>
    setData("queuedKnowledgeChange", (queue) => {
      if (queue.removed.includes(asset)) {
        return {
          added: queue.added,
          removed: queue.removed.filter((r) => r !== asset),
        };
      }
      return {
        added: [...queue.added, asset],
        remove: queue.removed,
      };
    });
  const removeQueuedKnowledgeChange = (asset: string) =>
    setData("queuedKnowledgeChange", (queue) => {
      if (queue.added.includes(asset)) {
        return {
          removed: queue.removed,
          added: queue.added.filter((r) => r !== asset),
        };
      }
      return {
        removed: [...queue.removed, asset],
        added: queue.added,
      };
    });

  const assetsListResource = createResource(
    () => !isConnecting(),
    async () => {
      try {
        const res = await client.controlplane.QueryAssetsForProject(
          deps.identityService.snapshot.context.identity.workingContext.projectId,
          {
            Limit: 50,
            Offset: 0,
            SortBy: "last_updated",
            SortDir: "desc",
            Search: "",
          },
        );
        batch(() => {
          res?.data?.forEach((d) => {
            setAssetsCache("byId", d.id, d);
          });
        });
        return res;
      } catch (error) {
        if ((error as { status: number }).status === 404) {
          return {
            OperationID: "",
            Duration: "",
            eventKey: "",
            code: "",
            message: "",
            details: {
              operation: {
                operationId: "",
                duration: 0,
              },
              consumption: {
                units: -1,
              },
            },
            data: [],
          } as operations.Response<q.ControlplaneSsAsset[]>;
        }

        throw error;
      }
    },
  );

  return {
    assetsListResource,
    queuedKnowledgeChange: () => data.queuedKnowledgeChange,
    resetQueuedKnowledgeChange,
    addQueuedKnowledgeChange,
    removeQueuedKnowledgeChange,
    assetsCache,
    setAssetsCache,
  };
};
