import type { stid } from "@repo/client";

// TODO: Guest needs to be dynamic based on deviceID.
export const GUEST_IDENTITY: UnauthenticatedIdentity = {
  isAuthenticated: false,
  workingContext: {
    organizationId: "" as stid.OrganizationStringID,
    tenantId: "" as stid.OrganizationStringID,
    projectId: "" as stid.ProjectStringID,
    directoryId: "" as stid.DirectoryStringID,
    interactionId: "" as stid.InteractionStringID,
    operationId: "" as stid.OperationStringID,
  },
  isGuest: true,
} as const;

export type WorkingContext = {
  organizationId: stid.OrganizationStringID;
  tenantId: stid.OrganizationStringID;
  projectId: stid.ProjectStringID;
  directoryId: stid.DirectoryStringID;
  interactionId: stid.InteractionStringID;
  operationId: stid.OperationStringID;
};

export type AuthenticatedIdentity = {
  userId: stid.UserStringID;
  displayName: string;
  email: string;
  emailVerified: boolean;
  isAuthenticated: boolean;
  didSignUp: boolean;
  pictureURL: string;
  workingContext: WorkingContext;
  isGuest: boolean;
  potentialScore: number;
};

export type UnauthenticatedIdentity = {
  isAuthenticated: boolean;
  workingContext: WorkingContext;
  isGuest: boolean;
};
export type Identity = AuthenticatedIdentity | UnauthenticatedIdentity;

/**
 * isAuthenticatedIdentity is a type guard for AuthenticatedIdentity.
 * @param arg
 */
export function isAuthenticatedIdentity(arg: unknown): arg is AuthenticatedIdentity {
  // biome-ignore lint/suspicious/noExplicitAny: type guard
  const o = arg as any;
  return o && typeof o.displayName === "string" && typeof o.isAuthenticated === "boolean" && o.isAuthenticated;
}

export type IdentityStore = {
  identity: Identity;
  error?: string;
};
